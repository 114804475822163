<script>

	import { onMount, onDestroy } from 'svelte';
	import { fly, fade } from 'svelte/transition';
	import { tweened } from 'svelte/motion';
	import { bounceOut, sineIn } from "svelte/easing";

	import { event } from './lib/stores.js';

	import Button from './ui/Button.svelte';

	import { isIOS, isIpadOS, isInStandaloneMode } from "./lib/device.js";
	import { refusedInstall, androidInstallPrompt } from './lib/stores.js';

	export let showInstallMessage = false;

	let mounted = false;
	let thanks = false;

	let panelIn = {
		y: 10,
		duration: 200,
		delay: 600,
		easing: sineIn
	};

	const progress = tweened(0, {
		duration: 6000,
		easing: sineIn
	});

	onMount(async () => {
		if ($androidInstallPrompt || isIOS()) {
			panelIn = {
				y: -80,
				duration: 800,
				delay: 200,
				easing: bounceOut
			};
		}
		mounted = true;
		setTimeout(() => {
			progress.set(1);
		}, 200);
	});

	function refuseInstall() {
		$refusedInstall = true;
		showInstallMessage = false;
	}

	function closePanel() {
		showInstallMessage = false;
	}

	function refuseOrClose() {
		if (thanks) {
			closePanel();
		} else {
			refuseInstall();
		}
	}

	async function androidInstall() {
		$androidInstallPrompt.prompt();
		const { outcome } = await $androidInstallPrompt.userChoice;
  		$androidInstallPrompt = null;
  		if (outcome === 'accepted') {
  			thanks = true;
  		} else if (outcome === 'dismissed') {
  			refuseInstall();
  		}
	}

</script>

<style>
	#installMessage {
		position: fixed;
		overflow: visible;
		left: 50%;
		transform: translateX(-50%);
		width: calc(100% - 1rem);
		max-width: 500px;
		bottom: calc(0.8rem + env(safe-area-inset-bottom));
		background: var(--textColor, #fff);
		color: var(--bgColor, #000);
		font-size: 1rem;
		padding: 2rem;
		border-radius: 6px;
		min-height: 8rem;
		/*display: grid;
		place-content: center;*/
		box-sizing: border-box;
		border: 1px solid var(--blend-20);
		box-shadow: 0 0 1rem var(--shadow, #000);
	}
	#installMessage.iphone:before {
		content: '';
		position: absolute;
		left: calc(50% - 6px);
		bottom: -6px;
		width: 12px;
		height: 12px;
		transform: rotate(45deg);
		background: var(--textColor, #fff);
		border: 1px solid var(--blend-20);
		box-shadow: 0 3px 1rem var(--shadow, #000);
	}
	#installMessage.iphone:after {
		content: '';
		position: absolute;
		inset: 0;
		background: var(--textColor, #fff);
		border-radius: 6px;
	}
	#installMessage > div {
		position: relative;
		z-index: 100;
		width: 100%;
	}
	#installMessage p path {
/*		fill: #30AAFF;*/
		fill: var(--bgColor, #000);
	}
	.close {
		position: absolute;
		top: -1rem;
		right: -1.5rem;
	}
	.icon {
		display: inline-block;
		margin: 0 auto;
		width: 3.4rem;
		height: 3.4rem;
		border-radius: 22.37%;
		overflow: hidden;
	}
	.icon > img {
		transform: scale(1.2);
	}
	p, h2 {
		text-align: center;
		line-height: 1.4;
		padding: 1rem 0;
	}
	h2 {
		font-size: 1.2rem;
	}
	.desc {
		padding-top: 0;
		font-size: 1rem;
		color: var(--blend-60);
		margin: 0 auto;
		max-width: 20rem;
	}
	.instructions {
		font-size: 1rem;
		border-top: 1px solid var(--blend-40);
		padding-bottom: 0;
	}
	.instructions svg {
		display: inline-block;
		vertical-align: middle;
/*		width: 28px;*/
		width: 1.9em;
		position: relative;
		top: -0.3em;
		margin: 0 0.1em;
	}
	.welcome {
		font-size: 1.8rem;
		margin-bottom: 4rem;
	}
	.proceed {
		position: relative;
		width: 100%;
		height: calc(3rem + 2px);
	}
	.proceed > div {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.timer {
		position: absolute;
		top: calc(50% - 5px);
		left: 0;
		width: 100%;
		height: 10px;
		background: var(--blend-90);
		border-radius: 10px;
	}
	.timer > div {
		width: calc(100% * var(--progress));
		background: var(--accentColor);
		height: 10px;
		border-radius: 10px;
	}
</style>

{#if showInstallMessage && mounted}
	<div
		id="installMessage"
		class:iphone={isIOS() && !isIpadOS()}
		in:fly={panelIn}
		out:fly={{ y: -20, duration: 200 }}
	>
		<div>
			<div class="close">
				<Button subtle={true} on:click={refuseOrClose}>
					<svg viewBox="0 0 80 80">
						<title>Close</title>
						<path d="M40.06 44.53l-11 11-4.5-4.49 11-11-11-11 4.5-4.5 11 11 11-11 4.5 4.49-11 11 11 11-4.5 4.49-11-10.99z"/>
					</svg>
				</Button>
			</div>
			<div class="icon">
				<img
					src="/images/icons/maskable_icon_x512.png"
					alt="Check-in"
				/>
			</div>
			{#if thanks}
				<h2 class="welcome">Thank you</h2>
				<div in:fade={{ duration: 400, delay: 800 }}>
					<Button wide={true} ghost={true} on:click={closePanel} label="Close" />
				</div>
			{:else if $androidInstallPrompt || isIOS()}
				<h2>Install on your device</h2>
				<p class="desc">Add this app to your home screen for easy access and a better experience.</p>
				{#if $androidInstallPrompt}
					<div in:fade={{ duration: 200, delay: 200}}>
						<Button wide={true} on:click={androidInstall} label="Install" />
					</div>
				{:else}
					<p class="instructions">
						<span>Tap</span>
						<svg viewBox="0 0 72 72"><path d="M54 21.8h-10v4h10c1.9 0 3.4 1.5 3.4 3.4v32c0 1.9-1.5 3.4-3.4 3.4h-36c-1.9 0-3.4-1.5-3.4-3.4v-32c0-1.9 1.5-3.4 3.4-3.4h10v-4h-10c-4.1 0-7.4 3.3-7.4 7.4v32c0 4.1 3.3 7.4 7.4 7.4h36c4.1 0 7.4-3.3 7.4-7.4v-32c0-4.1-3.3-7.4-7.4-7.4zm-6.4-7.6l-10.2-10.2c-.8-.8-2-.8-2.8 0l-10.2 10.2c-.8.8-.8 2 0 2.8.8.8 2 .8 2.8 0l6.8-6.8v33.8c0 1.1.9 2 2 2s2-.9 2-2v-33.8l6.8 6.8c.4.4.9.6 1.4.6s1-.2 1.4-.6c.8-.8.8-2 0-2.8z"/></svg>
						<span>then Add to Home Screen</span>
						<svg viewBox="0 0 72 72"><path d="M47.7 40.3h-9.7v-9.7c0-1.1-.9-2-2-2s-2 .9-2 2v9.7h-9.7c-1.1 0-2 .9-2 2s.9 2 2 2h9.7v9.7c0 1.1.9 2 2 2s2-.9 2-2v-9.7h9.7c1.1 0 2-.9 2-2s-.9-2-2-2zm3.6 28.3h-30.6c-6.1 0-11-4.9-11-11v-30.6c0-6.1 4.9-11 11-11h30.6c6.1 0 11 4.9 11 11v30.6c0 6-4.9 11-11 11zm-30.6-48.6c-3.9 0-7 3.1-7 7v30.6c0 3.9 3.1 7 7 7h30.6c3.9 0 7-3.1 7-7v-30.6c0-3.9-3.1-7-7-7h-30.6z"/></svg>
					</p>
				{/if}
			{:else}
				<h2 class="welcome">Welcome</h2>
				<div class="proceed">
					{#if $progress == 1}
						<div in:fade={{ duration: 400, delay: 500 }}>
							<Button wide={true} on:click={closePanel} label="Tap to continue" />
						</div>
					{:else}
						<div class="timer" style="--progress:{$progress}" in:fade={{ duration: 400, delay: 400}} out:fade|local={{ duration: 400 }}>
							<div></div>
						</div>
					{/if}
				</div>
			{/if}
		</div>
	</div>
{/if}